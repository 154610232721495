angular
    .module('annexaApp')
    .component('annexaTramTemplateTransactions',{
        templateUrl: './components/admin/annexa-tram-template-transactions/annexa-tram-template-transactions.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

        	vm.getName = function(item){
				if(item && item[vm.languageColumn]){
					return item[vm.languageColumn];
				}else{
					return '';
				}
			}

			vm.saveTramTemplateTransaction = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var tramTemplateTransaction = {
						language1:model.language1,
						language2:model.language2,
						language3:model.language3,
						keyId:model.keyId
					}
					if(vm.isNew){
						vm.data.transactions.push(tramTemplateTransaction);
						self.close();
					}else{
						tramTemplateTransaction.tramTemplate = ((vm.data && vm.data.id)?{id:vm.data.id}:undefined);
						RestService.insert('./api/tram_template_transaction', tramTemplateTransaction).then(function (data) {
							vm.data.transactions.push(data);
			        		self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
			            });
					}
				}
			}
			
			vm.deleteTramTemplateTransaction = function(item, index){
				if(vm.isNew){
					vm.data.transactions.splice(index, 1);
				}else{
					if(item && item.id){
						RestService.delete('./api/tram_template_transaction/' + item.id).then(function (data) {
		        			vm.data.transactions.splice(index, 1);
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.data.transactions.splice(index, 1);
					}
				}	
			}
			
			vm.newTramTemplateTransaction = function(){
				var modal = angular.copy(CommonAdminModals.tramTemplateTransactionNew);
				modal.annexaFormly.options = {};
				AnnexaFormlyFactory.showModal('modalTramTemplateTransactionNew', modal, vm.saveTramTemplateTransaction, false);
			}
			
			vm.saveEditTramTemplateTransaction = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					if(vm.isNew){
						self.temp.item.language1 = model.language1;
						self.temp.item.language2 = model.language2;
						self.temp.item.language3 = model.language3;
						self.temp.item.keyId = model.keyId;
						self.close();
					}else{
						itemAux.language1 = model.language1;
						itemAux.language2 = model.language2;
						itemAux.language3 = model.language3;
						itemAux.keyId = model.keyId;
						itemAux.tramTemplate = {id:vm.data.id};
						RestService.update('./api/tram_template_transaction/' + itemAux.id, itemAux).then(function (data) {
							self.temp.item.language1 = model.language1;
							self.temp.item.language2 = model.language2;
							self.temp.item.language3 = model.language3;
							self.temp.item.keyId = model.keyId;
			        		self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			            });
					}
				}
			}
			
			vm.editTramTemplateTransaction = function(item){
				var modal = angular.copy(CommonAdminModals.tramTemplateTransactionNew);
				modal.annexaFormly.options = {};
				modal.temp = {item:item};
				modal.annexaFormly.model = {modal_body: {
					language1:item.language1,
					language2:item.language2,
					language3:item.language3,
					keyId:item.keyId
				}}
				AnnexaFormlyFactory.showModal('modalTramTemplateTransactionEdit', modal, vm.saveEditTramTemplateTransaction, false);
			}

        	this.$onInit = function () {
        		if(!vm.data){
        			vm.data.transactions = [];
        			vm.isNew = true;
        		}else{
        			if(vm.data.id){
        				vm.isNew = false;
        			}
        			if(!vm.data.transactions){
        				vm.data.transactions = [];
        			}
        		}
            }
        }],
        bindings: {
            data: '='
        }
    })
