angular
    .module('annexaApp')
    .component('annexaAutomatismRelated',{
        templateUrl: './components/admin/annexa-automatism-related/annexa-automatism-related.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'AdminFactory', 
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, AdminFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();

        	vm.getName = function(item){
				if(item && item.relatedAutomatism != null && item.relatedAutomatism[vm.languageColumn]){
					return item.relatedAutomatism[vm.languageColumn];
				}else{
					return '';
				}
			}

			vm.saveRelated = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var raField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'relatedAutomatism'");
					var autSelected = undefined;
					if(raField && raField.templateOptions && raField.templateOptions.options){
						autSelected = $linq(raField.templateOptions.options).firstOrDefault(undefined, "x => x.id == "+this.annexaFormly.model.modal_body.relatedAutomatism);	
					}
					var related = {
						relatedAutomatism:((autSelected && autSelected.id)?{id:autSelected.id, language1:autSelected.language1, language2:autSelected.language2, language3:autSelected.language3}:((model.relatedAutomatism)?{id:model.relatedAutomatism, language1:model.relatedAutomatism, language2:model.relatedAutomatism, language3:model.relatedAutomatism}:undefined)),
						viewOrder:model.viewOrder
					}
					if(vm.data && !vm.data.actualId){
						vm.recalculateOrder(model.relatedAutomatism, model.viewOrder, undefined, false, true)
						vm.data.relateds.push(related);
						self.close();
					}else{
						related.actualAutomatism = {id:vm.data.actualId};
						RestService.insert('./api/automatism_related', related).then(function (data) {
							vm.recalculateOrder(model.relatedAutomatism, model.viewOrder, undefined, false, true)
							vm.data.relateds.push(data);
			        		self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
			            });
					}
				}
			}
			
			vm.deleteRelated = function(item, index){
				if(vm.data && !vm.data.actualId){
					vm.data.relateds.splice(index, 1);
					vm.recalculateOrder(item.relatedAutomatism, item.viewOrder, undefined, true, false)
				}else{
					if(item && item.id){
						RestService.delete('./api/automatism_related/' + item.id).then(function (data) {
		        			vm.data.relateds.splice(index, 1);
		        			vm.recalculateOrder(item.relatedAutomatism, item.viewOrder, undefined, true, false)
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
			            });
					}else{
						vm.data.relateds.splice(index, 1);
						vm.recalculateOrder(item.relatedAutomatism, item.viewOrder, undefined, true, false)
					}
				}	
			}
			
			vm.newRelated = function(){
				var modal = angular.copy(CommonAdminModals.automatismRelatedNew);
				modal.annexaFormly.options = {};
				modal.annexaFormly.model = {modal_body: {
					actualId: vm.data.actualId,
					relatedIds: $linq(vm.data.relateds).select("x => x.relatedAutomatism.id").toArray()
				}}
				var viewOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'viewOrder'");
                if(viewOrderField) {
                	viewOrderField.templateOptions.maxValue = vm.data.relateds.length + 1;
                }
				AnnexaFormlyFactory.showModal('modalAutomatismRelatedNew', modal, vm.saveRelated, false);
			}
			
			vm.recalculateOrder = function(autId, viewOrder, oldViewOrder, deleted, newAR){
				_.forEach(vm.data.relateds, function(rel){
					if(deleted){
						if(rel.viewOrder >= viewOrder){
							rel.viewOrder = rel.viewOrder - 1;
						}
					}else if(newAR){
						if(rel.viewOrder >= viewOrder){
							rel.viewOrder = rel.viewOrder + 1;
						}
					}else{
						if(oldViewOrder > viewOrder) {
	    					//si es mes petit que l'antic i es mes gran que el que volem ficar s'ha de moure a una posició més
	    					if(rel.viewOrder && viewOrder && oldViewOrder && rel.viewOrder < oldViewOrder &&  rel.viewOrder >= viewOrder) {
	    						rel.viewOrder = rel.viewOrder + 1;
	    					}
	    				}else if(oldViewOrder < viewOrder){ // si modifiquem un i el fiquem despres
	    					//si es mes gran que l'antic i es mes petit que el que volem ficar s'ha de moure a una posició menys
	    					if(rel.viewOrder && viewOrder && oldViewOrder && rel.viewOrder > oldViewOrder &&  rel.viewOrder <= viewOrder) {
	    						rel.viewOrder = rel.viewOrder - 1;
	    					}
	    				}
					}
				})
	    	}
			
			vm.saveEditRelated = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var itemAux = angular.copy(this.temp.item);
					var raField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'relatedAutomatism'");
					var autSelected = undefined;
					if(raField && raField.templateOptions && raField.templateOptions.options){
						autSelected = $linq(raField.templateOptions.options).firstOrDefault(undefined, "x => x.id == "+this.annexaFormly.model.modal_body.relatedAutomatism);	
					}
					if(vm.data && !vm.data.actualId){
						vm.recalculateOrder(model.relatedAutomatism, model.viewOrder, this.temp.item.viewOrder, false, false)
						self.temp.item.relatedAutomatism = ((autSelected && autSelected.id)?{id:autSelected.id, language1:autSelected.language1, language2:autSelected.language2, language3:autSelected.language3}:((model.relatedAutomatism)?{id:model.relatedAutomatism, language1:model.relatedAutomatism, language2:model.relatedAutomatism, language3:model.relatedAutomatism}:undefined));
						self.temp.item.viewOrder = model.viewOrder;
						self.close();
					}else{
						itemAux.relatedAutomatism =((autSelected && autSelected.id)?{id:autSelected.id, language1:autSelected.language1, language2:autSelected.language2, language3:autSelected.language3}:((model.relatedAutomatism)?{id:model.relatedAutomatism, language1:model.relatedAutomatism, language2:model.relatedAutomatism, language3:model.relatedAutomatism}:undefined));
						itemAux.viewOrder = model.viewOrder;
						itemAux.actualAutomatism = {id:vm.data.actualId};
						RestService.update('./api/automatism_related/' + itemAux.id, itemAux).then(function (data) {
							vm.recalculateOrder(model.relatedAutomatism, model.viewOrder, self.temp.item.viewOrder, false, false)
							self.temp.item.relatedAutomatism = ((autSelected && autSelected.id)?{id:autSelected.id, language1:autSelected.language1, language2:autSelected.language2, language3:autSelected.language3}:((model.relatedAutomatism)?{id:model.relatedAutomatism, language1:model.relatedAutomatism, language2:model.relatedAutomatism, language3:model.relatedAutomatism}:undefined));
							self.temp.item.viewOrder = model.viewOrder;
			        		self.close();
			            }).catch(function (error) {
			            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			            });
					}
				}
			}
			
			vm.editRelated = function(item){
				var modal = angular.copy(CommonAdminModals.automatismRelatedNew);
				modal.annexaFormly.options = {};
				modal.temp = {item:item};
				modal.annexaFormly.model = {modal_body: {
					relatedAutomatism:((item.relatedAutomatism)?item.relatedAutomatism.id:undefined),
					viewOrder:item.viewOrder,
					actualId: vm.data.actualId,
					relatedIds: $linq(vm.data.relateds).select("x => x.relatedAutomatism.id").toArray()
				}}
				var viewOrderField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'viewOrder'");
                if(viewOrderField) {
                	viewOrderField.templateOptions.maxValue = vm.data.relateds.length;
                }
				AnnexaFormlyFactory.showModal('modalAutomatismRelatedEdit', modal, vm.saveEditRelated, false);
			}

        	this.$onInit = function () {
        		if(!vm.data){
        			vm.data.relateds = [];
        		}else{
        			if(!vm.data.relateds){
        				vm.data.relateds = [];
        			}
        		}
            }
        }],
        bindings: {
            data: '='
        }
    })
